import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    position: "relative",
    display: "list-item"
  },
  circle: {
    width: "56px",
    height: "56px",
    borderRadius: "50%",
    backgroundColor: "#eaeaea",
    position: "absolute"
  },
  circleContent: { position: "relative" },
  circleValue: {
    fontSize: "25px",
    fontWeight: "bold",
    paddingTop: "5px",
    color: "#555"
  },
  circleLabel: {
    marginTop: "-10px",
    fontSize: "15px",
    color: "#707070"
  }
});

const BubbleIndicator = ({ value, label, classes }) => {
  return (
    <Grid item xs>
      <div className={classes.container}>
        <div className={classes.circleContent}>
          <Typography
            variant="display1"
            gutterBottom
            align="center"
            className={classes.circleValue}
          >
            {value}
          </Typography>
          <Typography
            variant="subheading"
            gutterBottom
            align="center"
            className={classes.circleLabel}
          >
            {label}
          </Typography>
        </div>
      </div>
    </Grid>
  );
};

export default withStyles(styles)(BubbleIndicator);
