import {view} from 'vitrarius'
import {servicesLens} from '../optical/lens/lens'
import {
  movementLatestTelescope, 
  movementAllTelescope, 
  clippingLatestTelescope, 
  clippingAllTelescope, 
  blockedTenantLatestTelescope} from '../optical/telescopes/telescopes'

import {
  FETCH_SERVICES_STATUS,
  FETCH_LATEST_MOVEMENT_STATUS,
  FETCH_ALL_MOVEMENT_STATUS,
  FETCH_LATEST_CLIPPING_STATUS,
  FETCH_ALL_CLIPPING_STATUS,
  FETCH_BLOCKED_TENANT_STATUS
} from "../actions/servicesActions";

const INITIAL_STATE = {
  services: [],
  movement: {
    latest: [],
    all: []
  },
  clipping: {
    AASP: {
      latest: [],
      all: []
    },
    VISTA: {
      latest: [],
      all: []
    }
  },
  search: {
    all: []
  },
  blockedTenants: {
    latest: [],
  }
};

const serviceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SERVICES_STATUS:
      return view(servicesLens(replace(state.services, action.data).sortByProperty('label')), state)
    case FETCH_LATEST_MOVEMENT_STATUS:
      return view(movementLatestTelescope(action.data), state)
    case FETCH_ALL_MOVEMENT_STATUS:
      return view(movementAllTelescope(action.data), state)
    case FETCH_LATEST_CLIPPING_STATUS: 
      return view(clippingLatestTelescope(action), state)
    case FETCH_ALL_CLIPPING_STATUS: 
      return view(clippingAllTelescope(action), state)
    case FETCH_BLOCKED_TENANT_STATUS:
        return view(blockedTenantLatestTelescope(action.data), state)
    default:
      return state;
  }
};

const replace = (servicesFromState, dataFromAction) => {
  const labelsFromData = dataFromAction.map(service => service.label)
  return servicesFromState.filter(service => !labelsFromData.includes(service.label)).concat(dataFromAction)
}

export default serviceReducer;
