import React from 'react';
import {Paper, Table, TableHead, TableBody, TableCell, TableRow, IconButton} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const styles = theme => ({
  head: {
      backgroundColor: theme.palette.background.default
  }
});

const exportErrors = async (item) => {
  const link = document.createElement('a');
  link.href = await URL.createObjectURL(new Blob([JSON.stringify(item.errors)], { type: 'application/json' }));
  link.download = "errors.json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const MovementList = ({list, classes}) => (
    <Paper>
      <Table>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell>Inicio</TableCell>
            <TableCell>Fim</TableCell>
            <TableCell numeric>Sucessos</TableCell>
            <TableCell numeric>Erros</TableCell>
            <TableCell numeric>Total</TableCell>
            <TableCell >Ambiente</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{item.start}</TableCell>
                <TableCell>{item.end}</TableCell>
                <TableCell numeric>{item.success}</TableCell>
                <TableCell numeric>{item.errorsCount}
                  <IconButton aria-label="delete" size="small">
                    <ArrowDownwardIcon fontSize="inherit" onClick={() => exportErrors(item)}/>
                  </IconButton>
                </TableCell>
                <TableCell numeric>{item.total}</TableCell>
                <TableCell>{item.environment}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
)

export default withStyles(styles)(MovementList);
