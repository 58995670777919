import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import {Typography, Grid} from '@material-ui/core';

import UpdatedAccountsChart from './charts/UpdatedAccountsChart';
import TimeSpentChart from './charts/TimeSpentChart';
import LastStatusCard from './charts/LastStatusCard';

const styles = theme => ({
    root: {
      padding: "10px"
    },
    spaceBetween: {
        marginBottom: 40
    },
    progress: {
      margin: theme.spacing.unit * 2
    }
});

const Details = ({title, last, getAccountsData, getTimeData, classes, children}) => (
    <div className={classes.root}>
        <Typography variant="display1" align="left" component="h2">
            {title}
        </Typography>
        {
            getTimeData && (
                <Grid container direction="row" justify="space-around" alignItems="center" className={classes.spaceBetween}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TimeSpentChart getData={getTimeData}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <LastStatusCard lastExecution={last}/>
                    </Grid>
                </Grid>
            )
        }
        
        <Grid container direction="row" justify="center" alignItems="center" className={classes.spaceBetween}>
            <Grid item xs={11}>
            { getAccountsData && (
                <UpdatedAccountsChart getData={getAccountsData} className={classes.spaceBetween}/>
                )
            }
            </Grid>
        </Grid>
        {children}
    </div>
)

export default withStyles(styles)(Details)