import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import {
    fetchAllBlockedTenants
} from "../../../actions/servicesActions";

import Loading from '../../Loading/Loading'
import BlockedTenantList from './BlockedTenantList';
import Details from '../Details';

class BlockedTenantDetails extends Component {

    componentWillMount(){
        this.props.fetchAllBlockedTenants();
    }

    render() {
        const {blockedTenants} = this.props
        console.log(blockedTenants.latest.length)
        if(!blockedTenants.latest.length) {
            console.log("Loading")
            return <Loading />
        } else {
            console.log(blockedTenants)
            return (
                <Details title="Tenants Bloqueados">
                    <BlockedTenantList list={blockedTenants.latest}/>
                </Details>
            )
        }
    }
}

const mapStateToProps = state => {
    console.log("state")
    console.log(state)
    return { blockedTenants: state.blockedTenants };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchAllBlockedTenants: () => {
            dispatch(fetchAllBlockedTenants());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlockedTenantDetails)
