import {API_BASE_URL, searchUrl} from '../../constants/apiRoutes';

export const all = () => {
    return fetch(`${API_BASE_URL}${searchUrl.BASE_URL}`, {headers: {'Content-type': 'application/json',}})
        .then(catchResponse)
        .then(makeResult)
        .catch(logError)
}

const catchResponse = (response) => (response.ok)? response.json() : Promise.reject(response)
const makeResult = (response) => (response['result'])
const logError = error => error.json().then((json) => console.log(json))