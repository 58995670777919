import React, {Component} from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import {fetchLatestClippings, fetchAllClippings} from '../../../actions/servicesActions';

import Loading from '../../Loading/Loading'
import Details from '../Details';
import ClippingList from './ClippingList';

class ClippingsDetails extends Component {

    componentWillMount(){
        const {params} = this.props.match;
        this.props.fetchLatestClippingStatus(params.provider);
        this.props.fetchAllClippingStatus(params.provider);
    }

    getLast = () => {
        const {params} = this.props.match;
        const clipping = this.props.clipping[params.provider];
        return clipping.latest[0];
    }

    mapAccountsData = () => {
        const {params} = this.props.match;
        const clipping = this.props.clipping[params.provider];
        return clipping.all.reduce((list, status) => {
            const formattedDate = moment(status.beginDate).format('MM/YYYY');
            const listItem = list.find(item => item.date === formattedDate);
            if(!listItem)
                return list.concat([{date: formattedDate, count: status.totalOffices, monthItems: 1}])
            
            listItem.count += status.totalOffices;
            listItem.monthItems++;
            return list;
        }, []).map(item => ({
            ...item,
            ['Contas atualizadas']: Math.ceil(item.count / item.monthItems)
        }))
    }

    mapTimeData = () => {
        const {params} = this.props.match;
        return this.props.clipping[params.provider].latest.map((item, index) => ({
            ...item,
            ['Tempo de atualização (em minutos)']: item.timeSpent,
            index: index + 1
        }));
    }

    render(){
        const {params} = this.props.match;
        const clippings = this.props.clipping[params.provider]
        if(!clippings.latest.length) return <Loading />
        return (
            <Details title={`Publicações ${params.provider}`} last={this.getLast()} getAccountsData={this.mapAccountsData} getTimeData={this.mapTimeData}>
                <ClippingList list={clippings.latest}/>
            </Details>
        )
    }
}

const mapStateToProps = state => {
    return { clipping: state.clipping };
};
  
const mapDispatchToProps = dispatch => {
    return {
        fetchLatestClippingStatus: provider => {
            dispatch(fetchLatestClippings(provider));
        },
        fetchAllClippingStatus: provider => {
            dispatch(fetchAllClippings(provider));
        }
    };
};
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClippingsDetails)