export const API_BASE_URL = "/api";

export const clippingUrl = {
    BASE_URL: "/clipping",
    LOAD_LAST_URL: "/:provider/last",
    LOAD_LATEST_URL: "/:provider/latest",
    LOAD_ALL_URL: "/:provider/all",
};

export const movementUrl = {
    BASE_URL: "/movement",
    LOAD_LAST_URL: "/last",
    LOAD_LATEST_URL: "/latest",
    LOAD_ALL_URL: "/all",
};

export const searchUrl = {
    BASE_URL: "/search",
};

export const blockedTenantUrl = {
    BASE_URL: "/blocked-tenants",
    SUMMARY_URL: "/summary",
    LOAD_ALL_URL: "/all"
};