import React from "react";
import { Grid, Chip, ListItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import StatusButton from "../StatusButton/StatusButton";
import { green, red } from "@material-ui/core/colors";
import { Link } from 'react-router-dom';

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit,
    minWidth: "80%",
    backgroundColor: "#505050",
    color: "#FFF"
  },
  button: {
    margin: theme.spacing.unit
  },
  error: {
    border: "1px solid transparent",
    borderLeft: "0px",
    borderImage: `linear-gradient(to left, ${red[800]}, #f000)`,
    borderImageSlice: 1,
    backgroundImage: "linear-gradient(to left, #f443360d, #0f00)",
    alignItems: "flex-start"
  },
  ok: {
    border: "1px solid transparent",
    borderLeft: "0px",
    borderImage: `linear-gradient(to left, ${green[800]}, #f000)`,
    borderImageSlice: 1,
    backgroundImage: "linear-gradient(190deg, #4caf500d, #0f00)",
    alignItems: "flex-start"
  }
});

const ServiceItem = ({ label, status, children, classes, route }) => {
  return (
    <ListItem className={classes[status]} component="div">
      <Grid item xs={4} md={3}>
        <Chip label={label} className={classes.chip} />
      </Grid>
      {children}
      <Link to={route}>
        <StatusButton status={status} />
      </Link>
    </ListItem>
  );
};

export default withStyles(styles)(ServiceItem);
