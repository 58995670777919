import React from "react";
import {
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Typography
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import logo from '../../assets/images/heimdall.png';
import { Link } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  }
})

const Header = ({classes}) => (
  <AppBar position="static">
    <Toolbar>
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <IconButton color="inherit" aria-label="Menu">
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Link to="/" className={classes.link}>
            <Typography variant="title" color="inherit" align="right">
              <img src={logo} width={30} height={30}/>
            </Typography>
          </Link>
        </Grid>
        <Grid item xs>
          <Link to="/" className={classes.link}>
            <Typography variant="title" color="inherit" align="left">
              H E I M D A L L
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
);

export default withStyles(styles)(Header)