import React from 'react';
import { Switch, Route } from 'react-router-dom'
import ServerList from '../ServicesList/ServicesList'
import MovementsDetails from '../Details/Movements/MovementsDetails'
import ClippingsDetails from '../Details/Clippings/ClippingsDetails'
import BlockedTenantDetails from '../Details/BlockedTenants/BlockedTenantDetails'

export default () => (
  <main>
    <Switch>
      <Route exact path='/' component={ServerList}/>
      <Route path="/movements" component={MovementsDetails}/>
      <Route path="/clippings/:provider" component={ClippingsDetails} />
      <Route path="/blocked-tenants" component={BlockedTenantDetails}/>
    </Switch>
  </main>
)