import React, { PureComponent } from "react";
import { Grid, List } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ServiceItem from "../ServiceItem/ServiceItem";
import BubbleIndicator from "../BubbleIndicator/BubbleIndicator";
import { connect } from "react-redux";
import {
  fetchServicesStatus, fetchLazyServicesStatus
} from "../../actions/servicesActions";
import Loading from '../Loading/Loading'

const styles = theme => ({
  root: {
    paddingTop: "50px",
    paddingLeft: "2%",
    paddingRight: "2%",
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});

class ServicesList extends PureComponent {
  componentWillMount() {
    const { fetchServices, fetchLazyServices } = this.props;
    fetchServices();
    fetchLazyServices();
    setInterval(fetchServices, 60000); // 1 minuto
    setInterval(fetchLazyServices, 1800000); //30 minutos
  }

  getBubbleIndicators = item => {
    return Object.values(item)
            .filter(value => typeof value === 'object')
            .map(({value, label}, index) => <BubbleIndicator key={index} value={value} label={label} />)
  };

  getItems = () => {
    return this.props.services.map((item, index) => (
        <ServiceItem
          key={index}
          label={item.label}
          status={item.errors.hasErrors ? "error" : "ok"}
          route={item.route}
        >
          {this.getBubbleIndicators(item)}
        </ServiceItem>
    ))
  }

  render() {
    const { classes, services } = this.props;
    if (!services.length) return <Loading />;

    return (
      <Grid
        container
        direction="row"
        justify="center"
        className={classes.root}
      >
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <List>
            {this.getItems()}
          </List>
        </Grid>
      </Grid>
    );
    
  }
}

const mapStateToProps = state => {
  return { services: state.services };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchServices: () => {
      dispatch(fetchServicesStatus());
    },
    fetchLazyServices: () => {
      dispatch(fetchLazyServicesStatus());
    }
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ServicesList)
);
