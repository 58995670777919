import {API_BASE_URL, movementUrl} from '../../constants/apiRoutes';

export const last = () => {
    return fetch(`${API_BASE_URL}${movementUrl.BASE_URL}${movementUrl.LOAD_LAST_URL}`, {headers: {'Content-type': 'application/json',}})
        .then(catchResponse)
        .then(makeResult)
        .catch(logError)
}

export const latest = () => {
    return fetch(`${API_BASE_URL}${movementUrl.BASE_URL}${movementUrl.LOAD_LATEST_URL}`, {headers: {'Content-type': 'application/json',}})
        .then(catchResponse)
        .then(makeResult)
        .catch(logError)
}

export const all = () => {
    return fetch(`${API_BASE_URL}${movementUrl.BASE_URL}${movementUrl.LOAD_ALL_URL}`, {headers: {'Content-type': 'application/json',}})
        .then(catchResponse)
        .then(makeResult)
        .catch(logError) 
}

const catchResponse = (response) => (response.ok)? response.json() : Promise.reject(response)
const makeResult = (response) => (response['result'])
const logError = error => error.json().then((json) => console.log(json))