import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from 'redux-thunk';
import serviceReducer from "./reducers/serviceReducer";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { BrowserRouter } from 'react-router-dom'
import "./utils/array";
import firebase from "./firebase/firebase";
import { GithubAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import { AuthInterceptor } from './utils/authInterceptor'

const authProvider = new GithubAuthProvider()
authProvider.addScope('repo')
authProvider.addScope('read:org')
const auth = getAuth(firebase);
signInWithPopup(auth, authProvider).then((result) => {

  AuthInterceptor()

  const theme = createMuiTheme({
    palette: {
      primary: { main: "#FAFAFA" },
      secondary: { main: "#212121" }
    },
    typography: {
      fontFamily: ["Oswald", "sans-serif"].join(",")
    }
  });

  const store = createStore(serviceReducer, applyMiddleware(thunk));

  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </MuiThemeProvider>,
    document.getElementById("root")
  );
})
