import React from 'react';
import {Card, Typography, CardContent, CardHeader, Grid} from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment'

const styles = () => ({
    header: {
        backgroundColor: '#eee'
    },
    card: {
        margin: 5
    },
    itemLeft: {
        borderRight: '1px solid #eee'
    },
    itemRight: {
        borderLeft: '1px solid #eee'
    },
    subtitle: {
        color: '#999999'
    },
    digital: {
        fontFamily: 'Digital-7'
    }
})

const LastStatusCard = ({lastExecution = {}, classes}) => (
    <Card className={classes.card}>
        <CardHeader className={classes.header} title="Dados da última atualização"/>
        <CardContent>
            <Grid container direction="row" justify="center">
                <Grid item xs={12} sm={6} md={6} lg={6} className={classes.itemLeft}>
                    <Typography variant="subheading" align="center" className={classes.subtitle}>
                        Tempo
                    </Typography>
                    <Typography variant="display2" align="center" className={classes.digital}>
                        {moment(lastExecution.timeSpent, 'mm').format('HH:mm')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} className={classes.itemRight}>
                    <Typography variant="subheading" align="center" className={classes.subtitle}>
                        Contas
                    </Typography>
                    <Typography variant="display2" align="center" className={classes.digital}>
                        {lastExecution.total}
                    </Typography>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
)

export default withStyles(styles)(LastStatusCard);