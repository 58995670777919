import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, LinearProgress } from "@material-ui/core";

const styles = () => ({
    colorPrimary: {
        backgroundColor: '#B2DFDB',
    },
    barColorPrimary: {
        backgroundColor: '#00695C',
    }
});
const Loading = ({classes}) => (<LinearProgress classes={classes} variant="query" />)

export default withStyles(styles)(Loading);