import React from "react";
import { Grid, Button } from "@material-ui/core";
import { ThumbUp, Error } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

const styles = theme => ({
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[500]
    }
  },
  fabRed: {
    color: theme.palette.common.white,
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[500]
    }
  }
});

const StatusButton = ({ status, classes }) => {
  const icon = status === "ok" ? <ThumbUp /> : <Error />;

  return (
    <Grid item md={1}>
      <Button
        variant="fab"
        aria-label="Status"
        className={status === "ok" ? classes.fabGreen : classes.fabRed}
      >
        {icon}
      </Button>
    </Grid>
  );
};

export default withStyles(styles)(StatusButton);
