import {blockedTenantService, clippingService, movementService, searchService} from '../services/index'

export const FETCH_SERVICES_STATUS = "FETCH_SERVICES_STATUS";
export const FETCH_LATEST_MOVEMENT_STATUS = "FETCH_LATEST_MOVEMENT_STATUS";
export const FETCH_ALL_MOVEMENT_STATUS = "FETCH_ALL_MOVEMENT_STATUS";
export const FETCH_LATEST_CLIPPING_STATUS = "FETCH_LATEST_CLIPPING_STATUS";
export const FETCH_ALL_CLIPPING_STATUS = "FETCH_ALL_CLIPPING_STATUS";
export const FETCH_BLOCKED_TENANT_STATUS = "FETCH_BLOCKED_TENANT_STATUS";

export const fetchServicesStatus = () => {
  return dispatch => {
    Promise.all([
      movementService.last(),
      clippingService.last('VISTA'),
      clippingService.last('AASP'),
      blockedTenantService.summary()
    ])
    .then(data => dispatch({type: FETCH_SERVICES_STATUS, data}))
  }
};

export const fetchLazyServicesStatus = () => {
  return dispatch => {
    Promise.all([
      searchService.all()
    ])
    .then(data => {
      extractDataFromArray(data)
      dispatch({type: FETCH_SERVICES_STATUS, data})
    })
  }
};

const extractDataFromArray = (data) => {
  let indexToRemove = null
  data.forEach(item => {
    if (Array.isArray(item)) {
      indexToRemove = data.indexOf(item)
      item.forEach(subItem => data.push(subItem))
    }
  });
  data.splice(indexToRemove, 1)
}

export const fetchLatestMovementStatus = () => {
  return dispatch => {
    movementService.latest().then(data => dispatch({type: FETCH_LATEST_MOVEMENT_STATUS, data}))
  }
}

export const fetchAllMovementStatus = () => {
  return dispatch => {
    movementService.all().then(data => dispatch({type: FETCH_ALL_MOVEMENT_STATUS, data}))
  }
}

export const fetchLatestClippings = provider => {
  return dispatch => {
    clippingService.latest(provider).then(data => dispatch({type: FETCH_LATEST_CLIPPING_STATUS, data, provider}))
  }
}

export const fetchAllClippings = provider => {
  return dispatch => {
    clippingService.all(provider).then(data => dispatch({type: FETCH_ALL_CLIPPING_STATUS, data, provider}))
  }
}

export const fetchAllBlockedTenants = () => {
  return dispatch => {
    blockedTenantService.all().then(data => dispatch({type: FETCH_BLOCKED_TENANT_STATUS, data}))
  }
}