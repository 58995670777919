import React from 'react';
import {Paper, Table, TableHead, TableBody, TableCell, TableRow} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  head: {
      backgroundColor: theme.palette.background.default
  }
});

const BlockedTenantList = ({list, classes}) => (
    <Paper>
      <Table>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell>Bloqueios</TableCell>
            <TableCell>Tenant</TableCell>
            <TableCell>Primeiro bloqueio</TableCell>
            <TableCell>Último bloqueio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{item.blockedTypeNames.join()}</TableCell>
                <TableCell>{item.tenantId}</TableCell>
                <TableCell>{item.createdDate}</TableCell>
                <TableCell>{item.lastUpdateDate}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
)

export default withStyles(styles)(BlockedTenantList);
