import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import {
    fetchLatestMovementStatus,
    fetchAllMovementStatus
} from "../../../actions/servicesActions";

import Loading from '../../Loading/Loading'
import MovementList from './MovementList';
import Details from '../Details';

class MovementsDetails extends Component {

    componentWillMount(){
        this.props.fetchAllMovementStatus();
        this.props.fetchLatestMovementStatus();
    }

    mapAccountsData = () => {
        return this.props.movement.all.reduce((list, status) => {
            const formattedDate = moment(status.start, 'DD/MM/YYYY HH:mm').format('MM/YYYY')
            const listItem = list.find(item => item.date === formattedDate);
            if(!listItem)
                return list.concat([{date: formattedDate, count: status.success, errors: status.errorsCount, monthItems: 1}])
            listItem.count += status.success;
            listItem.errors += status.errorsCount;
            listItem.monthItems++;
            return list;
        }, []).map(item => {
            item['Contas atualizadas'] = Math.ceil(item.count / item.monthItems);
            item['Contas com falha'] = Math.ceil(item.errors / item.monthItems);
            return item;
        })
    }

    mapTimeData = () => {
        return this.props.movement.latest.map((item, index) => {
            item['Tempo de atualização (em minutos)'] = item.timeSpent;
            item.index = index+1;
            return item;
        })
    }

    getLast = () => {
        return this.props.movement.latest[this.props.movement.latest.length - 1];
    }

    render() {
        const {movement} = this.props
        if(!movement.latest.length) return <Loading />
        return (
            <Details title="Andamentos" last={this.getLast()} getAccountsData={this.mapAccountsData} getTimeData={this.mapTimeData}>
                <MovementList list={movement.latest}/>
            </Details>
        )
    }
}

const mapStateToProps = state => {
    return { movement: state.movement };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchLatestMovementStatus: () => {
            dispatch(fetchLatestMovementStatus());
        },
        fetchAllMovementStatus: () => {
            dispatch(fetchAllMovementStatus());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MovementsDetails)
