import {API_BASE_URL, blockedTenantUrl} from '../../constants/apiRoutes';

export const summary = () => {
    return fetch(`${API_BASE_URL}${blockedTenantUrl.BASE_URL}${blockedTenantUrl.SUMMARY_URL}`, {headers: {'Content-type': 'application/json',}})
        .then(catchResponse)
        .then(makeResult)
        .catch(logError)
}

export const all = () => {
    return fetch(`${API_BASE_URL}${blockedTenantUrl.BASE_URL}${blockedTenantUrl.LOAD_ALL_URL}`, {headers: {'Content-type': 'application/json',}})
        .then(catchResponse)
        .then(makeResult)
        .catch(logError)
}

const catchResponse = (response) => (response.ok)? response.json() : Promise.reject(response)
const makeResult = (response) => (response['result'])
const logError = error => error.json().then((json) => console.log(json))