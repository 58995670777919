import React from 'react';
import {LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Legend, Tooltip} from 'recharts';

export default ({getData}) => (
    <ResponsiveContainer width="99%" height={300}>
        <LineChart data={getData()} margin={{top: 40}}>
            <Line type="monotone" dataKey="Contas atualizadas" stroke="#78a6d3"/>
            <Line type="monotone" dataKey="Contas com falha" stroke="#ff0000"/>
            <CartesianGrid stroke="#ccc" vertical={false} strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip/>
            <Legend/>
        </LineChart>
    </ResponsiveContainer>
)
