import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import React from 'react';
import { Paper, Table, TableHead, TableBody, TableCell, TableRow, IconButton } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';

const styles = theme => ({
  head: {
      backgroundColor: theme.palette.background.default
  }
});

const exportDetails = async (item) => {
  const link = document.createElement('a');
  link.href = await URL.createObjectURL(new Blob([JSON.stringify(item)], { type: 'application/json' }));
  link.download = "details.json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getOldestClipping = item => {
  return item.releaseDates && item.releaseDates.length > 0 && item.releaseDates.reduce((prev, curr) => prev < curr ? prev : curr)
}

const getNewestClipping = item => {
  return item.releaseDates && item.releaseDates.length && item.releaseDates.reduce((prev, curr) => prev > curr ? prev : curr)
}

const ClippingList = ({list, classes}) => (
    <Paper>
      <Table>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell>Inicio</TableCell>
            <TableCell>Fim</TableCell>
            <TableCell numeric>Publicações</TableCell>
            <TableCell>Disponibilizações recebidas</TableCell>
            <TableCell>Disponibilização mais antiga</TableCell>
            <TableCell>Disponibilização mais recente</TableCell>
            <TableCell numeric>Contas</TableCell>
            <TableCell numeric>Sucessos</TableCell>
            <TableCell numeric>Erros</TableCell>
            <TableCell >Provedor</TableCell>
            <TableCell >Detalhes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{moment(item.beginDate).format('DD/MM/YYYY HH:mm')}</TableCell>
                <TableCell>{moment(item.endDate).format('DD/MM/YYYY HH:mm')}</TableCell>
                <TableCell numeric>{item.clippingsSize}</TableCell>
                <TableCell numeric>{item.releaseDates.length}</TableCell>
                <TableCell numeric>{getOldestClipping(item)}</TableCell>
                <TableCell numeric>{getNewestClipping(item)}</TableCell>
                <TableCell numeric>{item.total}</TableCell>
                <TableCell numeric>{item.success}</TableCell>
                <TableCell numeric>{item.errors}</TableCell>
                <TableCell>{item.provider}</TableCell>
                <TableCell>
                  <IconButton aria-label="delete" size="small">
                    <ArrowDownwardIcon fontSize="inherit" onClick={() => exportDetails(item)}/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </Paper>
)

export default withStyles(styles)(ClippingList);
