import React from 'react';
import {ResponsiveContainer, XAxis, YAxis, Legend, Tooltip, LineChart, Line} from 'recharts';

export default ({getData}) => {
    const data = getData();
    return (
        <ResponsiveContainer width="99%" height={250}>
            <LineChart data={data} margin={{top: 40}}>
                <XAxis dataKey="index"/>
                <YAxis/>
                <Tooltip/>
                <Legend />
                <Line dataKey="Tempo de atualização (em minutos)" stroke="#ff1616" />
            </LineChart>
        </ResponsiveContainer>
    )
}