import {API_BASE_URL, clippingUrl} from '../../constants/apiRoutes';

export const last = provider => {
    return fetch(
            `${API_BASE_URL}${clippingUrl.BASE_URL}${clippingUrl.LOAD_LAST_URL.replace(':provider', provider)}`,
            {headers: {'Content-type': 'application/json',}})
        .then(catchResponse)
        .then(makeResult)
        .catch(logError)
}

export const latest = provider => {
    return fetch(
            `${API_BASE_URL}${clippingUrl.BASE_URL}${clippingUrl.LOAD_LATEST_URL.replace(':provider', provider)}`,
            {headers: {'Content-type': 'application/json',}})
        .then(catchResponse)
        .then(makeResult)
        .catch(logError)
}

export const all = provider => {
    return fetch(
            `${API_BASE_URL}${clippingUrl.BASE_URL}${clippingUrl.LOAD_ALL_URL.replace(':provider', provider)}`,
            {headers: {'Content-type': 'application/json',}})
        .then(catchResponse)
        .then(makeResult)
        .catch(logError)
}

const catchResponse = (response) => (response.ok)? response.json() : Promise.reject(response)
const makeResult = (response) => (response['result'])
const logError = error => error.json().then((json) => console.log(json))