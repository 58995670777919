import fetchIntercept from 'fetch-intercept';
import { getAuth } from "firebase/auth";
import firebase from "./../firebase/firebase";

const authProvider = () => getAuth(firebase).currentUser.getIdToken()

export const AuthInterceptor = () => {
    
  fetchIntercept.register({
    request: function (url, config) {
      return authProvider().then(token => {
        config.headers.Authorization = `Bearer ${token}`
        return [url, config]
      })
      .catch((error) => Promise.reject(error))
    }
  });
};