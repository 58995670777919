import { initializeApp } from 'firebase/app';

var config = {
  apiKey: "AIzaSyAMf5RP5fi0mA0pEGcO-eetcBWh-tUQ6DE",
  authDomain: "hidra-api.firebaseapp.com",
  databaseURL: "https://hidra-api.firebaseio.com",
  projectId: "hidra-api",
  messagingSenderId: "167751300345",
  storageBucket: "hidra-api.appspot.com"
};

const firebase = initializeApp(config);
export default firebase;
